(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/tipsen-shared/assets/javascripts/create-url-search-params.js') >= 0) return;  svs.modules.push('/components/sport/tipsen-shared/assets/javascripts/create-url-search-params.js');
"use strict";

const mapToUrlSearchParams = map => {
  const searchParams = new URLSearchParams();
  map.forEach((value, key) => {
    searchParams.set(key, value);
  });
  return searchParams;
};

const objectToUrlSearchParams = obj => {
  const searchParams = new URLSearchParams();
  const keyValues = Object.entries(obj);
  keyValues.forEach(_ref => {
    let [key, value] = _ref;
    searchParams.set(key, value);
  });
  return searchParams;
};

const createUrlSearchParams = searchParams => {
  let providedSearchParams;
  if (searchParams instanceof URLSearchParams) {
    providedSearchParams = searchParams;
  } else if (searchParams instanceof Map) {
    providedSearchParams = mapToUrlSearchParams(searchParams);
  } else if (typeof searchParams === 'object') {
    providedSearchParams = objectToUrlSearchParams(searchParams);
  } else if (typeof searchParams === 'string') {
    providedSearchParams = new URLSearchParams(searchParams);
  }
  return providedSearchParams;
};
setGlobal('svs.components.sport.tipsenShared.createUrlSearchParams', createUrlSearchParams);

 })(window);