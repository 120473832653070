(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/tipsen-shared/assets/javascripts/transform-signs.js') >= 0) return;  svs.modules.push('/components/sport/tipsen-shared/assets/javascripts/transform-signs.js');
"use strict";


const {
  signIndexToOutcome,
  outcomeToSignIndex,
  convertToSigns
} = svs.components.sport.tipsenShared;
const {
  EventTypeId
} = svs.components.sportinfo.common.constants;
const FULLTRAFF_LENGTH_OF_SIGNS = 8;
const convertSignsTo1X2 = signs => {
  const outcomes = [];
  for (let x = 0; x < signs.length; ++x) {
    outcomes[x] = '';
    for (let i = 0; i < signs[x].length; ++i) {
      if (signs[x][i]) {
        outcomes[x] += signIndexToOutcome[EventTypeId.EVENT_1X2][i];
      }
    }
  }
  return outcomes;
};
const convert1X2ToSigns = boardData => boardData.map(outcomeText => {
  const signs = [false, false, false];
  outcomeText.toUpperCase().split('').forEach(sign => {
    signs[outcomeToSignIndex[EventTypeId.EVENT_1X2][sign]] = true;
  });
  return signs;
});

const convertFulltraffPixEventsToSigns = events => events.map(_ref => {
  let {
    outcomes
  } = _ref;
  const signsRow = new Array(FULLTRAFF_LENGTH_OF_SIGNS).fill(false);
  outcomes.forEach(_ref2 => {
    let {
      value
    } = _ref2;
    signsRow[value] = true;
  });
  return signsRow;
});

const convertFulltraffPixEventsToWagerBoard = events => events.map(_ref3 => {
  let {
    outcomes
  } = _ref3;
  return outcomes.map(_ref4 => {
    let {
      value
    } = _ref4;
    return String(value);
  });
});
const convertScoreOutcomesToSigns = _ref5 => {
  let {
    selectedOutcomes,
    eventTypeId,
    sportType
  } = _ref5;
  const eventSigns = selectedOutcomes.map(eventOutcomes => {
    const outcomesToConvertToSigns = [];
    for (const teamOutcome of eventOutcomes) {
      outcomesToConvertToSigns.push({
        outcomes: teamOutcome.toString(),
        eventTypeId,
        sportType
      });
    }
    return convertToSigns(outcomesToConvertToSigns, eventTypeId);
  });
  return eventSigns;
};

setGlobal('svs.components.sport.tipsenShared.transformSigns', {
  convertSignsTo1X2,
  convert1X2ToSigns,
  convertFulltraffPixEventsToSigns,
  convertFulltraffPixEventsToWagerBoard,
  convertScoreOutcomesToSigns
});

 })(window);