(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/tipsen-shared/assets/javascripts/bitmap.js') >= 0) return;  svs.modules.push('/components/sport/tipsen-shared/assets/javascripts/bitmap.js');
"use strict";



const BITS_PER_INDEX = 30;

const isBitSet = (decimalValue, index) => Boolean(decimalValue & 1 << index);

const createNewBitmap = numOutcomes => new Array(Math.floor(numOutcomes / BITS_PER_INDEX + 1)).fill(0);

const getBitmapValue = (bitmap, index) => isBitSet(bitmap[Math.floor(index / BITS_PER_INDEX)], index % BITS_PER_INDEX);

const setBitmapValue = (bitmap, index, value) => {
  if (value) {
    bitmap[Math.floor(index / BITS_PER_INDEX)] |= 1 << index % BITS_PER_INDEX;
  } else {
    bitmap[Math.floor(index / BITS_PER_INDEX)] &= ~(1 << index % BITS_PER_INDEX);
  }
  return bitmap;
};

const toggleBitmapValue = (bitmap, index) => {
  const value = getBitmapValue(bitmap, index);
  setBitmapValue(bitmap, index, Number(!value));
  return bitmap;
};

const resetBitmap = bitmap => {
  for (let i = 0; i < bitmap.length; i++) {
    bitmap[i] = 0;
  }
  return bitmap;
};

const combine = (bitmap, bitmapToMerge) => {
  for (let i = 0; i < bitmap.length; i++) {
    bitmap[i] |= bitmapToMerge[i];
  }
  return bitmap;
};

const calculateMSB = decimalValue => {
  if (decimalValue <= 0) {
    return -1;
  }
  return Math.floor(Math.log2(decimalValue));
};

const bitmapToArray = bitmap => {
  let highestGroup = -1;
  for (let index = bitmap.length - 1; index >= 0; index--) {
    const hasValue = Boolean(bitmap[index]);
    if (hasValue) {
      highestGroup = index;
      break;
    }
  }
  const msb = calculateMSB(bitmap[highestGroup]);
  const length = highestGroup * BITS_PER_INDEX + msb + 1;
  const bitmapAsArray = [];
  for (let i = 0; i < length; i++) {
    bitmapAsArray.push(getBitmapValue(bitmap, i));
  }
  return bitmapAsArray;
};

const arrayToBitmap = array => {
  const bitmap = [];
  for (let i = 0; i < array.length; i += BITS_PER_INDEX) {
    let chunk = 0;
    const maxIndex = Math.min(i + BITS_PER_INDEX, array.length);
    for (let j = i; j < maxIndex; j++) {
      if (array[j]) {
        chunk |= 1 << j - i;
      }
    }
    bitmap.push(chunk);
  }
  return bitmap;
};

const hasBitmapValues = bitmap => Boolean(bitmap.find(Boolean));

const clearLSB = decimalValue => decimalValue & decimalValue - 1;

const countSetBits = decimalValue => {
  let count = 0;
  while (decimalValue) {
    decimalValue = clearLSB(decimalValue);
    count++;
  }
  return count;
};

const isOnlyOneBitSet = decimalValue => decimalValue !== 0 && clearLSB(decimalValue) === 0;

const getSetBitsInBitmap = bitmap => {
  let selectedBits = 0;
  for (const chunk of bitmap) {
    selectedBits += countSetBits(chunk);
  }
  return selectedBits;
};

const getBinaryNumbers = decimalValue => {
  const binaryNumbers = [];
  let i = 0;
  while (decimalValue !== 0) {
    if (decimalValue & 1) {
      binaryNumbers.push(2 ** i);
    }
    decimalValue >>= 1;
    i++;
  }
  return binaryNumbers;
};
const getBitmapBinaryNumbers = bitmap => {
  const binaryNumbers = [];
  for (const chunk of bitmap) {
    binaryNumbers.push(getBinaryNumbers(chunk));
  }
  return binaryNumbers;
};

const binaryToArray = decimalValue => {
  const arrayLength = calculateMSB(decimalValue) + 1;
  const array = [];
  for (let i = 0; i < arrayLength; i++) {
    array.push(isBitSet(decimalValue, i));
  }
  return array;
};

const toggleBitValue = (decimalValue, bit) => decimalValue ^ bit;

const getBitmapIndices = bitmap => {
  const indices = bitmap.map(getBinaryNumbers).map((chunk, i) => chunk.map(v => calculateMSB(v) + i * BITS_PER_INDEX));
  return indices;
};
const createBitMapWithInitialValue = (numOutcomes, index) => {
  const bitMap = createNewBitmap(numOutcomes);
  toggleBitmapValue(bitMap, index);
  return bitMap;
};
const toExport = {
  BITS_PER_INDEX,
  createNewBitmap,
  getBitmapValue,
  setBitmapValue,
  toggleBitmapValue,
  resetBitmap,
  combine,
  bitmapToArray,
  arrayToBitmap,
  hasBitmapValues,
  countSetBits,
  isOnlyOneBitSet,
  getSetBitsInBitmap,
  getBinaryNumbers,
  getBitmapBinaryNumbers,
  binaryToArray,
  toggleBitValue,
  getBitmapIndices,
  createBitMapWithInitialValue
};
if (svs.isServer) {
  module.exports = toExport;
} else {
  setGlobal('svs.components.sport.tipsenShared.bitmap', toExport);
}

 })(window);