(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/tipsen-shared/assets/javascripts/get-persisted-search-params.js') >= 0) return;  svs.modules.push('/components/sport/tipsen-shared/assets/javascripts/get-persisted-search-params.js');
"use strict";

const {
  PARAM_MARKETPLACE_GAME_ID,
  PARAM_DRAWNUMBER,
  PARAM_PRODUCT_ID
} = svs.components.sport.tipsenShared;

const persistedMarketplaceQueryParameters = [PARAM_MARKETPLACE_GAME_ID, PARAM_DRAWNUMBER, PARAM_PRODUCT_ID];
const getPersistedSearchParams = (existingSearchParams, persistedQueryParameters) => {
  if (!persistedQueryParameters) {
    persistedQueryParameters = persistedMarketplaceQueryParameters;
  }
  const persistedParams = new URLSearchParams();
  for (const persistedParam of persistedQueryParameters) {
    if (existingSearchParams.has(persistedParam)) {
      persistedParams.set(persistedParam, existingSearchParams.get(persistedParam));
    }
  }
  return persistedParams;
};
setGlobal('svs.components.sport.tipsenShared.getPersistedSearchParams', getPersistedSearchParams);

 })(window);