(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/tipsen-shared/assets/javascripts/get-appended-persisted-search-params.js') >= 0) return;  svs.modules.push('/components/sport/tipsen-shared/assets/javascripts/get-appended-persisted-search-params.js');
"use strict";

const {
  createUrlSearchParams,
  getPersistedSearchParams
} = svs.components.sport.tipsenShared;


const getAppendedPersistedSearchParams = (location, searchParams, persistedKeys) => {
  const existingSearchParams = new URLSearchParams(location.search);
  const finalSearchParams = new URLSearchParams(getPersistedSearchParams(existingSearchParams, persistedKeys));
  const providedSearchParams = createUrlSearchParams(searchParams);
  if (providedSearchParams) {
    providedSearchParams.forEach((value, key) => {
      finalSearchParams.set(key, value);
    });
  }
  return finalSearchParams;
};
setGlobal('svs.components.sport.tipsenShared.getAppendedPersistedSearchParams', getAppendedPersistedSearchParams);

 })(window);